import React from "react";
import {
  SEO,
  PageLayout,
  MarkdownContent,
  FadeReveal,
  Image,
  FilteredList,
  MultipleComponentIterator,
  InternalLink,
  MultipleComponentIteratorMap,
  FullImageCard,
  FishermanIcon,
  DynamicScript,
} from "@bluefin/components";
import { Grid, Header, Button, Card } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
  withNamedArgs,
  getBusinessFiles,
  withArgs,
} from "../utils/utils";
import {
  createServiceCategorySlug,
  createServiceItemSlug,
} from "@bluefin/gatsby-plugin-fisherman-page-creator";

export default class ServicesPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessMenuCategory,
      allFishermanBusinessMenuSchedule,
      allFishermanBusinessFile,
      allFishermanBusinessMenuItem,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={false} className={"services-page"}>
          <div className={"content-section"}>
            <Grid stackable={true} doubling={true} columns={2}>
              <Grid.Column width={8} className={"markdown-column"}>
                <Header
                  as={"h1"}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "content_section_header",
                    defaultValue: "Services",
                  })}
                />
                <MarkdownContent
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "content_section_description",
                  })}
                />
              </Grid.Column>
              <Grid.Column width={8} className={"image-column"}>
                <FadeReveal triggerOnce={true}>
                  <Image
                    className={"image"}
                    background={true}
                    src={getComponentFiles({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "Content.Image",
                      componentIdentifier: "content_section_image",
                      numToSelect: 1,
                    })}
                  />
                </FadeReveal>
              </Grid.Column>
            </Grid>
          </div>
          <Grid
            stackable={true}
            className={"component-section-container swiper-widget"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={14} textAlign={""}>
              <div className={"swiper-container"}>
                <div className={"swiper-wrapper"} />
                <div className={"swiper-pagination"} />
                <div className={"swiper-button-prev"} />
                <div className={"swiper-button-next"} />
              </div>
            </Grid.Column>
          </Grid>
          <FilteredList
            items={allFishermanBusinessMenuCategory.nodes}
            itemFilterKey={"schedule.name"}
            childItemsKey={"iterator"}
            defaultFilterValue={"All Services"}
            displayAllFilterValue={"All Services"}
            filterValues={allFishermanBusinessMenuSchedule.nodes}
            filterValueKey={"name"}
            filterValuesComponent={<Button basic={true} />}
            className={"services-filtered-list"}
            parentContainer={<div className={"services-section"} />}
          >
            <MultipleComponentIterator
              components={[
                {
                  component: <div className={"service-container"} />,
                  propMap: { key: "_id" },
                  children: [
                    {
                      component: (
                        <Grid
                          className={"category-info"}
                          stackable={true}
                          doubling={true}
                          columns={2}
                        />
                      ),
                      children: [
                        {
                          component: (
                            <Grid.Column className={"info-column"} width={8} />
                          ),
                          children: [
                            {
                              component: (
                                <Header className={"name"} as={"h2"} />
                              ),
                              propMap: { content: "name" },
                            },
                            {
                              component: (
                                <MarkdownContent className={"description"} />
                              ),
                              propMap: { content: "description" },
                            },
                            {
                              component: (
                                <Button
                                  basic={true}
                                  content={"View Details"}
                                  event={{
                                    category: "Services",
                                    action: "View Category Details",
                                  }}
                                  as={InternalLink}
                                />
                              ),
                              propMap: {
                                "event.label": "name",
                                to: createServiceCategorySlug,
                              },
                            },
                          ],
                        },
                        {
                          component: (
                            <Grid.Column className={"image-column"} width={8} />
                          ),
                          children: [
                            {
                              component: <Image className={"image"} />,
                              propMap: {
                                src: withNamedArgs({
                                  func: getBusinessFiles,
                                  args: {
                                    businessFiles:
                                      allFishermanBusinessFile.nodes,
                                    numToSelect: 1,
                                  },
                                }),
                              },
                            },
                          ],
                        },
                      ],
                    },
                    {
                      component: (
                        <Card.Group
                          className={"category-items"}
                          stackable={true}
                          itemsPerRow={2}
                        />
                      ),
                      children: [
                        {
                          component: (
                            <MultipleComponentIteratorMap
                              lookupField={"_id"}
                              limit={4}
                              iterator={allFishermanBusinessMenuItem.nodes}
                              components={[
                                {
                                  component: (
                                    <FadeReveal
                                      className={"ui card"}
                                      triggerOnce={true}
                                    />
                                  ),
                                  propMap: { key: "_id" },
                                  children: [
                                    {
                                      component: (
                                        <FullImageCard
                                          as={InternalLink}
                                          link={true}
                                          event={{
                                            category: "Services",
                                            action: "View Item Details",
                                          }}
                                        />
                                      ),
                                      propMap: {
                                        image: withNamedArgs({
                                          func: getBusinessFiles,
                                          args: {
                                            businessFiles:
                                              allFishermanBusinessFile.nodes,
                                            numToSelect: 1,
                                          },
                                        }),
                                        "event.label": "name",
                                        to: withArgs({
                                          func: createServiceItemSlug,
                                          args: [
                                            allFishermanBusinessMenuCategory.nodes,
                                          ],
                                        }),
                                      },
                                      children: [
                                        {
                                          component: <Card.Header as={"h3"} />,
                                          propMap: { content: "name" },
                                        },
                                        {
                                          component: (
                                            <FishermanIcon
                                              iconName={"arrow-right"}
                                            />
                                          ),
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ]}
                            />
                          ),
                          propMap: { targetValues: "items" },
                        },
                      ],
                    },
                  ],
                },
              ]}
            />
          </FilteredList>
        </PageLayout>
        <DynamicScript
          type={"application/javascript"}
          delay={false}
          header={true}
          body={
            'async function fetchDataAndPopulateSlider(){let e=new Date,t=e.toISOString();try{let a=await fetch("https://na0.meevo.com/onlinebooking/api/ob/FiveStarRatings/Rating/Detail/List?tenantId=101532&locationId=103908",{method:"POST",headers:{"Content-Type":"application/json"},body:JSON.stringify({itemsPerPage:30,pageNumber:0,criteria:{employeeIds:[],serviceCategoryId:null,serviceIds:[],rating:null,isHidden:!1,isDeleted:!1,startDate:"1974-02-18T14:00:23.050Z",endDate:t,locationId:103908,tenantId:101532,clientId:"five-star-rating",fieldOrderBy:1,orderAscDesc:"Descending"}})}),i=await a.json(),n=document.querySelector(".swiper-wrapper");i.forEach(e=>{let t=document.createElement("div");t.className="swiper-slide",t.innerHTML=`\n<h3>${e.displayName}</h3>\n<p>Service by: ${e.employeeNickName} ${e.employeeLastName.charAt(0)}.</p>\n<p>${new Date(e.serviceUsageDateTime).toLocaleDateString("en-US",{year:"numeric",month:"short",day:"numeric"})}</p>\n<p>${"★".repeat(e.rating)}</p>\n${e.comment?`<p>${e.comment}</p>`:""}\n <p>- ${e.clientFirstName} ${e.clientLastName.charAt(0)}</p>\n`,n.appendChild(t)}),new Swiper(".swiper-container",{loop:!0,centeredSlides:!0,spaceBetween:30,pagination:{el:".swiper-pagination",dynamicBullets:!0},navigation:{nextEl:".swiper-button-next",prevEl:".swiper-button-prev"},observer:!0,observeParents:!0,breakpoints:{320:{slidesPerView:1,spaceBetween:20},480:{slidesPerView:2,spaceBetween:30},640:{slidesPerView:3,spaceBetween:40},800:{slidesPerView:4,spaceBetween:50}}})}catch(r){console.error("Error fetching data:",r)}}fetchDataAndPopulateSlider();'
          }
        />
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Services" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
    }
    allFishermanBusinessMenuCategory(
      sort: { fields: [isFeatured, order], order: [DESC, ASC] }
    ) {
      nodes {
        description
        name
        _id
        items
        scheduleId
        schedule {
          _id
          name
        }
        files
      }
    }
    allFishermanBusinessMenuSchedule(sort: { order: ASC, fields: order }) {
      nodes {
        _id
        name
        order
      }
    }
    allFishermanBusinessFile {
      nodes {
        _id
        file
        description
        gatsbyFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    allFishermanBusinessMenuItem(
      sort: { fields: [isFeatured, order], order: [DESC, ASC] }
      filter: { visible: { eq: true } }
    ) {
      nodes {
        _id
        name
        description
        image
        visible
        available
        files
      }
    }
  }
`;
